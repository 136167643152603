<template>
  <div class="style-box-setting">
    <div class="style-box-setting-header">
      <v-row>
        <v-col cols="5">
          <div class="title-header">
            Gestion des services
          </div>
        </v-col>
        <v-col cols="1">
          <v-progress-circular
            class="ml-2"
            v-if="loadingService"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </v-col>
        <v-col cols="6">
          <div class="error-message ml-2 mr-2 mt-3">
            <div v-if="errorService">
              <ul v-if="Array.isArray(errorService)" class="mb-0">
                <li v-for="(e, index) in errorService" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ errorService }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="box-content">
      <div class="card-block-filter-setting">
        <div class="right-block-filter"></div>
        <div class="left-block-filter">
          <div class="search-input">
            <search
              :value="searchValue"
              @changeSearchValue="changeSearchValue"
              hiddenContent="true"
            />
          </div>
          <v-tooltip bottom color="#311B92" v-if="checkPermission('GSHTAS')">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center"
                v-on="on"
                v-bind="attrs"
                @click.prevent="handleModalService('add', 'show')"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span>Ajouter Service</span>
          </v-tooltip>
        </div>
      </div>
      <div class="content-card-table custom-gestion-service-card">
        <v-data-table
          v-model="selectedTable"
          :headers="fields"
          :items="services"
          class="table-setting"
          hide-default-footer
          :calculate-widths="false"
          :fixed-header="true"
          :items-per-page="perPage"
          hide-action
          :disable-sort="true"
          :no-data-text="
            loadingService
              ? 'Chargement en cours ...'
              : `Il n'y a aucun enregistrement à afficher`
          "
        >
          <!--NOM-->
          <template v-slot:[`item.nom`]="{ item }">
            <td class="custom-cell">{{ item.nom }}</td>
          </template>
          <!--TYPE-->
          <template v-slot:[`item.type`]="{ item }">
            <td class="custom-cell">{{ item.type }}</td>
          </template>
          <!--DESCRIPTION-->
          <template v-slot:[`item.description`]="{ item }">
            <td class="custom-cell">
              {{ item.description ? item.description : '-' }}
            </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <td class="custom-cell">
              <div class="d-flex actions-style-service">
                <div
                  class="bloc-icon-gestion mr-1"
                  v-if="checkPermission('GSHTMS')"
                  @click.prevent.stop="handleUpdateService(item)"
                  title="Modifier Service"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </div>
                <div
                  v-if="checkPermission('GSHTSS')"
                  class="bloc-icon-gestion mr-1"
                  @click.prevent.stop="handleDeleteService(item)"
                  title="Supprimer Service"
                >
                  <font-awesome-icon icon="trash" />
                </div>
                <div
                  v-if="checkPermission('GSHTMS')"
                  class="bloc-icon-gestion mr-1"
                  @click.prevent.stop="duplicateService(item)"
                  title="Dupliquer Service"
                >
                  <font-awesome-icon icon="copy" />
                </div>
              </div>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
    <div class="footer-style-table">
      <div class="text-center pagination-table ">
        <v-pagination
          v-model="page"
          :length="totalPages"
          @input="pagination"
          :total-visible="7"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify mr-2">
        <v-select
          v-model="perPage"
          :items="perPageList"
          @change="changePerPage"
          label="Eléments par page"
          color="#704ad1"
          item-color="#704ad1"
          outlined
          dense
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetY: true,
            closeOnClick: true,
            closeOnContentClick: true
          }"
        ></v-select>
      </div>
    </div>
    <!--MODAL ADD SERVICE-->
    <v-dialog
      v-model="addService"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter Service </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalService('add', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <form class="form-add" ref="formAdd">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nom"
                  v-model="serviceToAdd.nom"
                  :persistent-placeholder="true"
                  :rules="rulesNom"
                  :error-messages="errorMessagesNom"
                  required
                  class="msg-error"
                  validate-on-blur
                  ref="nom"
                  color="#704ad1"
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Description"
                  class="msg-error"
                  v-model="serviceToAdd.description"
                  :error-messages="errorMessagesDescription"
                  :persistent-placeholder="true"
                  ref="description"
                  :rules="rulesDescription"
                  validate-on-blur
                  outlined
                  rows="3"
                  color="#704ad1"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="serviceToAdd.type"
                  :items="typeProduct"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                  class="mb-4"
                  label="Type"
                  item-color="#704ad1"
                  color="#704ad1"
                  :persistent-placeholder="true"
                  placeholder="Sélectionner"
                  no-data-text="Aucun element trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-select>
              </v-col>
            </v-row>
          </form>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)" class="mb-0">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div class="message-error-modal" v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="submitAddService"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalService('add', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL UPDATE SERVICE-->
    <v-dialog
      v-model="updateModalService"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card v-if="serviceToUpdate && serviceToUpdate != null">
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Modifier Service </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalService('update', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <v-form class="form-add" ref="formUpdate">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nom"
                  v-model="serviceToUpdate.nom"
                  :persistent-placeholder="true"
                  :rules="rulesNom"
                  required
                  dense
                  validate-on-blur
                  class="msg-error mt-2"
                  ref="nom"
                  color="#704ad1"
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Description"
                  class="msg-error"
                  v-model="serviceToUpdate.description"
                  :persistent-placeholder="true"
                  ref="description"
                  :rules="rulesDescription"
                  validate-on-blur
                  outlined
                  rows="3"
                  color="#704ad1"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="serviceToUpdate.type"
                  :items="typeProduct"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                  class="mb-4"
                  label="Type"
                  item-color="#704ad1"
                  color="#704ad1"
                  :persistent-placeholder="true"
                  placeholder="Sélectionner"
                  no-data-text="Aucun element trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-form>
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ error }}</div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="submitUpdateService"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalService('update', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL DUPLICATE SERVICE-->
    <v-dialog
      v-model="duplicateModalService"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card v-if="serviceToUpdate && serviceToUpdate != null">
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Dupliquer Service </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalService('duplicate', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <v-form class="form-add" ref="formDuplicate">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nom"
                  v-model="serviceToUpdate.nom"
                  :persistent-placeholder="true"
                  :rules="rulesNom"
                  required
                  validate-on-blur
                  class="msg-error"
                  ref="nom"
                  color="#704ad1"
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Description"
                  class="msg-error"
                  v-model="serviceToUpdate.description"
                  :persistent-placeholder="true"
                  ref="description"
                  :rules="rulesDescription"
                  validate-on-blur
                  outlined
                  rows="3"
                  color="#704ad1"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="serviceToUpdate.type"
                  :items="typeProduct"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                  class="mb-4"
                  label="Type"
                  item-color="#704ad1"
                  color="#704ad1"
                  :persistent-placeholder="true"
                  placeholder="Sélectionner"
                  no-data-text="Aucun element trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-form>
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ error }}</div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="submitDuplicateService"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalService('duplicate', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL DELETE SERVICE-->
    <v-dialog
      v-model="deleteModalService"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Supprimer Service </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalService('delete', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <div class="mt-4 ml-3 text-center">
            <p>
              Êtes-vous sur de vouloir supprimer ce service
              <span class="bold-text"
                >{{
                  serviceToDelete && serviceToDelete.nom
                    ? serviceToDelete.nom
                    : ''
                }}
              </span>
              ?
            </p>
          </div>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)" class="mb-0">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div class="message-error-modal" v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="submitDeleteService"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalService('delete', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      selectedTable: [],
      errorMessagesNom: [],
      errorMessagesAdresse: [],
      errorMessagesDescription: [],
      typeProduct: [
        { value: 'prestation', text: 'Prestation' },
        { value: 'marchandise', text: 'Marchandise' }
      ],
      page: 1,
      rulesNom: [v => !!v || 'Nom est obligatoire.'],
      rulesAdresse: [v => !!v || 'Adresse est obligatoire.'],
      rulesDescription: [v => !!v || 'Description est obligatoire.'],
      addService: false,
      updateModalService: false,
      duplicateModalService: false,
      deleteModalService: false,
      perPage: 50,
      perPageList: [
        { value: 50, text: 50 },
        { value: 100, text: 100 },
        { value: 200, text: 200 }
      ],
      fields: [
        { value: 'nom', text: 'Nom' },
        { value: 'type', text: 'Type' },

        { value: 'description', text: 'Description' },
        {
          value: 'actions',
          text: 'Actions',
          width: '60px !important'
        }
      ],
      serviceToAdd: {
        nom: null,
        description: null,
        type: 'prestation'
      },
      loading: false,
      serviceToDelete: null,
      serviceToUpdate: null,
      searchValue: null,
      error: null
    };
  },
  methods: {
    ...mapActions([
      'resetCommit',
      'getServices',
      'createService',
      'updateService',
      'deleteService'
    ]),
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
    },
    handleModalService(method, action) {
      if (method == 'add') {
        if (action == 'show') {
          this.addService = true;
        } else {
          this.addService = false;
          this.resetModal();
          this.$refs.formAdd.reset();
        }
      }
      if (method == 'update') {
        if (action == 'show') {
          this.updateModalService = true;
        } else {
          this.updateModalService = false;
          this.resetModal();
          this.$refs.formUpdate.reset();
        }
      }
      if (method == 'duplicate') {
        if (action == 'show') {
          this.duplicateModalService = true;
        } else {
          this.duplicateModalService = false;
          this.resetModal();
          this.$refs.formDuplicate.reset();
        }
      }

      if (method == 'delete') {
        if (action == 'show') {
          this.deleteModalService = true;
        } else {
          this.deleteModalService = false;
          this.serviceToDelete = {};
          this.error = null;
        }
      }
    },
    duplicateService(item) {
      this.serviceToUpdate = { ...item };
      this.handleModalService('duplicate', 'show');
    },
    async submitDuplicateService() {
      if (this.$refs.formDuplicate.validate()) {
        this.loading = true;
        const response = await this.createService(this.serviceToUpdate);
        if (response.success) {
          this.loading = false;
          this.handleModalService('duplicate', 'hide');
        } else {
          this.error = response.error;
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
    },
    async submitDeleteService() {
      this.error = null;
      this.loading = true;
      const response = await this.deleteService(this.serviceToDelete);
      if (response.success) {
        this.loading = false;
        this.handleModalService('delete', 'hIde');
      } else {
        this.loading = false;
        this.error = response.error;
      }
    },
    handleDeleteService(item) {
      this.serviceToDelete = item;
      this.handleModalService('delete', 'show');
    },
    handleUpdateService(item) {
      this.serviceToUpdate = { ...item };
      this.handleModalService('update', 'show');
    },

    async submitUpdateService() {
      this.error = null;
      this.loading = true;

      if (this.$refs.formUpdate.validate()) {
        const response = await this.updateService(this.serviceToUpdate);
        if (response.success) {
          this.loading = false;
          this.handleModalService('update', 'hide');
        } else {
          this.loading = false;
          this.error = response.error;
        }
      } else {
        this.loading = false;
      }
    },
    async submitAddService() {
      this.error = null;
      this.loading = true;
      if (this.$refs.formAdd.validate()) {
        const response = await this.createService(this.serviceToAdd);
        if (response.success) {
          this.loading = false;
          this.handleModalService('add', 'hide');
        } else {
          this.loading = false;
          this.error = response.error;
        }
      } else {
        this.loading = false;
      }
    },
    async handleFilter() {
      await this.getServices({
        perPage: this.perPage,
        page: this.page,
        service: this.searchValue
      });
    },
    async changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    async pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    resetModal() {
      this.loading = false;
      this.serviceToAdd = {
        nom: null,
        description: null,
        type: 'prestation'
      };
      this.error = null;
      this.serviceToDelete = {};
      this.serviceToUpdate = {};
      this.resetCommit();
    }
  },
  computed: {
    ...mapGetters([
      'checkPermission',
      'services',
      'loadingService',
      'errorService',
      'getTotalRowService'
    ]),
    totalPages() {
      if (this.getTotalRowService) {
        return Math.ceil(this.getTotalRowService / this.perPage);
      }
      return this.getTotalRowService;
    }
  },
  components: {
    search: () => import('@/views/component/SearchInputComponent.vue')
  },
  mounted() {
    this.handleFilter();
  }
};
</script>
<style lang="scss" scoped>
.actions-style-service {
  position: relative;
  margin: auto;
  justify-content: center;
}
.custom-gestion-service-card {
  height: calc(100vh - 274px) !important;
}
</style>

<template>
  <div class="gestion-categorie-th style-box-setting">
    <div class="style-box-setting-header">
      <v-row>
        <v-col cols="6">
          <div class="title-header">
            Gestion des catégories
          </div>
        </v-col>
        <v-col cols="2">
          <v-progress-circular
            class="ml-2"
            v-if="loadingForFetch"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </v-col>
        <v-col cols="4">
          <div class="error-message ml-2 mr-2 mt-3">
            <div v-if="categoriesError">
              <ul v-if="Array.isArray(categoriesError)" class="mb-0">
                <li v-for="(e, index) in categoriesError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ categoriesError }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="box-content">
      <div class="card-block-filter-setting pt-1 pb-1">
        <div class="right-block-filter"></div>
        <div class="left-block-filter">
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center mr-1"
                v-on="on"
                v-bind="attrs"
                @click="openModalAjouterCategories"
                v-if="checkPermission('GCATTHAC')"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span> Ajouter une catégorie</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div class="table-gestion-content-category pt-2">
      <v-data-table
        v-model="selectedTable"
        :headers="fields"
        :items="getCategories"
        :items-per-page="perPage"
        class="table-gestion-category"
        hide-default-footer
        disable-sort
        :calculate-widths="false"
        :fixed-header="true"
        hide-action
        :no-data-text="
          loadingForFetch
            ? 'Chargement... Veuillez patienter'
            : `Il n'y a aucune donnée à afficher dans ce tableau.`
        "
      >
        <template v-slot:[`item.nom_categorie`]="{ item }">
          {{ item.nom_categorie }}
        </template>
        <template v-slot:[`item.Actions`]="{ item }">
          <td class="custom-cell">
            <div class="d-flex actions-style-table">
              <div
                class="bloc-icon-gestion mr-2"
                title="Modifier"
                v-if="checkPermission('GCATTHMC')"
                @click.prevent.stop="updateCurrentCategorie(item)"
              >
                <font-awesome-icon icon="pencil-alt" />
              </div>
              <div
                class="bloc-icon-gestion mr-2"
                title="Supprimer"
                v-if="checkPermission('GCATTHSC')"
                @click.prevent.stop="deleteCurrentCategorie(item)"
              >
                <font-awesome-icon icon="trash" />
              </div>
            </div>
          </td>
        </template>
      </v-data-table>
    </div>
    <div class="footer-style-table">
      <div class="text-center pagination-table ">
        <v-pagination
          v-model="page"
          :length="totalPages"
          @input="pagination"
          :total-visible="7"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify mr-2">
        <v-select
          v-model="perPage"
          :items="perPageList"
          @change="changePerPage"
          label="Eléments par page"
          color="#704ad1"
          item-color="#704ad1"
          outlined
          dense
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetY: true,
            closeOnClick: true,
            closeOnContentClick: true
          }"
        ></v-select>
      </div>
    </div>
    <v-dialog
      v-model="addCategories"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter une catégorie</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('addCategories')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="addCategories" lazy-validation>
            <v-text-field
              label="Nom de catégorie"
              v-model="nom_categorie"
              outlined
              required
              dense
              :persistent-placeholder="true"
              :rules="[v => !!v || 'Nom de catégorie est obligatoire']"
              class="msg-error mt-2"
              validate-on-blur
              color="#704ad1"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul
            v-if="Array.isArray(error)"
            class="mb-0"
            style="list-style-type: none"
          >
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            @click="handleSubmitCategorie"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('addCategories')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="updateCategorieModal"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Modifier une catégorie</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('updateCategorieModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="updateCategorieModal" lazy-validation>
            <v-text-field
              label="Nom de catégorie"
              v-model="nom_categorie"
              outlined
              required
              dense
              :persistent-placeholder="true"
              :rules="[v => !!v || 'Nom de catégorie est obligatoire']"
              class="msg-error mt-2"
              validate-on-blur
              color="#704ad1"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <div v-if="error" class="message-error-modal">
          <ul
            v-if="Array.isArray(error)"
            class="mb-0"
            style="list-style-type: none"
          >
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            @click="handleUpdateCategorie"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('updateCategorieModal')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteCategorie"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Supprimer une catégorie</v-label>

          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('deleteCategorie')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div class="mt-4 ml-3">
            <p>
              Êtes-vous sur de vouloir supprimer cette catégorie
              <span class="bold-text">{{
                categorieToDelete ? categorieToDelete.nom_categorie : ''
              }}</span>
              ?
            </p>
          </div>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="loading"
            :class="{ loader: loading }"
            @click.prevent.stop="handleDeleteCategorie"
            >Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('deleteCategorie')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Gestion-des-catégories',
  data() {
    return {
      selectedTable: [],
      nom_categorie: null,
      error: null,
      loadingForFetch: false,
      loading: false,
      nameToUpdate: null,
      perPage: 20,
      page: 1,
      perPageList: [
        { value: 20, text: 20 },
        { value: 30, text: 30 },
        { value: 50, text: 50 }
      ],
      fields: [
        {
          value: 'nom_categorie',
          text: 'Nom catégorie',
          width: '100% !important'
        },
        { value: 'Actions', text: 'Actions' }
      ],
      categorieToDelete: null,
      categorieToUpdate: null,
      addCategories: false,
      deleteCategorie: false,
      updateCategorieModal: false
    };
  },
  computed: {
    ...mapGetters([
      'getCategories',
      'getCategoriesIsLoading',
      'categoriesError',
      'getCategoriesDetailsLoading',
      'getTotalRowCategories',
      'checkPermission'
    ]),
    totalPages() {
      if (this.getTotalRowCategories) {
        return Math.ceil(this.getTotalRowCategories / this.perPage);
      }
      return this.getTotalRowCategories;
    }
  },
  methods: {
    ...mapActions([
      'fetchAllCategories',
      'addNewCategorie',
      'deleteCategory',
      'updateCategorie'
    ]),
    openModalAjouterCategories() {
      this.addCategories = true;
    },
    resetModal() {
      this.nom_categorie = null;
      this.error = null;
      this.loading = false;
    },
    hideModal(ref) {
      this[ref] = false;
      this.resetModal();
      if (ref == 'updateCategorieModal') {
        this.$refs.updateCategorieModal.reset();
      }
      if (ref == 'addCategories') {
        this.$refs.addCategories.reset();
      }
    },
    async changePerPage() {
      this.loadingForFetch = true;
      this.page = 1;
      await this.fetchAllCategories({
        per_page: this.perPage,
        page: this.page
      });
      this.loadingForFetch = false;
    },
    async pagination(paginate) {
      this.loadingForFetch = true;
      this.page = paginate;
      await this.fetchAllCategories({
        per_page: this.perPage,
        page: this.page
      });
      this.loadingForFetch = false;
    },

    async handleSubmitCategorie() {
      if (this.$refs.addCategories.validate()) {
        this.loading = true;
        const response = await this.addNewCategorie({
          nom_categorie: this.nom_categorie
        });
        if (response) {
          this.loading = false;
          this.hideModal('addCategories');
        } else {
          this.error = this.categoriesError;
          this.loading = false;
        }
      }
    },
    deleteCurrentCategorie(data) {
      this.categorieToDelete = data;
      this.deleteCategorie = true;
    },

    async handleDeleteCategorie() {
      this.loading = true;
      const response = await this.deleteCategory(this.categorieToDelete);
      if (response) {
        this.loading = false;
        this.hideModal('deleteCategorie');
        if (this.getCategories.length === 0 && this.page > 1) {
          let newPage = this.page - 1;
          this.pagination(newPage);
        }
      } else {
        this.loading = false;
        this.error = this.categoriesError;
      }
    },
    updateCurrentCategorie(data) {
      this.nom_categorie = data.nom_categorie;
      this.categorieToUpdate = data;
      this.updateCategorieModal = true;
    },
    async handleUpdateCategorie() {
      if (this.$refs.updateCategorieModal.validate()) {
        this.loading = true;
        const response = await this.updateCategorie({
          categorie: this.categorieToUpdate,
          nom_categorie: this.nom_categorie
        });
        if (response) {
          this.loading = false;
          this.hideModal('updateCategorieModal');
        } else {
          this.loading = false;
          this.error = this.categoriesError;
        }
      }
    }
  },
  components: {},
  async mounted() {
    this.loadingForFetch = true;
    await this.fetchAllCategories({ per_page: this.perPage, page: this.page });
    this.loadingForFetch = false;
  }
};
</script>

<style lang="scss" scoped>
.table-gestion-content-category {
  padding: 0px 5px 0px 5px;
  max-height: calc(100vh - 240px) !important;
  height: calc(100vh - 268px) !important;
  margin-bottom: 0;
}
.gestion-categorie-th {
  height: 100%;
  .tabs-categorie {
    width: 25%;
    margin: 5px 15px;
    .custom-list-group {
      .list-group-item {
        justify-content: space-between;
        color: #515356;
        display: block;
        font-weight: 600;
        .list-item {
          justify-content: space-between;
        }
      }
    }
    .title {
      color: #515356;
      display: block;
      font-weight: 600;
      margin: 0px 0px 10px 0px;
    }
    .categorie-labels {
      display: flex;
      justify-content: space-between;
    }
    .trash-style {
      margin-left: 5px;
      color: #dc3545;
    }
  }

  .categorie-body {
    display: flex;
    margin: 5px 15px;
    height: calc(100vh - 86px);
    .categorie-details {
      width: 75%;
      .title {
        color: #515356;
        display: block;
        font-weight: 600;
        margin: 5px;
      }
      .input-modal-champ {
        input {
          border-radius: 5px;
        }
      }
      .custom-table-style {
        padding: 5px;
        max-height: calc(100vh - 198px);
        height: calc(100% - 198px);
        width: 100%;
      }
    }
  }
  .ligne-box-setting-header {
    margin-top: 1%;
  }
  .table-gestion-categories-style {
    max-height: calc(100vh - 247px) !important;
    height: calc(100vh - 247px) !important;
    margin-bottom: 0px;
    max-width: 100%;
  }
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
.widthTitle {
  width: 180px;
}
.loaderPosition {
  display: flex;
  flex-direction: row;
}
</style>
<style lang="scss">
.table-gestion-category {
  table {
    border-collapse: collapse;
  }
  height: 100% !important;
  max-height: 100% !important;
  .v-data-table__wrapper {
    max-height: calc(100vh - 280px) !important;
    height: calc(100vh - 280px) !important;
    margin-bottom: 0;
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  .table {
    table-layout: fixed !important;
    border-collapse: collapse !important;
  }
  th.text-start {
    min-width: 150px !important;
    width: 150px !important;
  }
  .v-data-table-header {
    text-align: center;
    .text-start {
      border: 1px solid #e5e5e5 !important;
      vertical-align: inherit;
      // white-space: nowrap;
      font-size: 11px;
      padding: 0px !important;
      text-align: center !important;
    }
  }
  tbody {
    text-align: center;
    color: #656565;

    td {
      border: 1px solid #e5e5e5 !important;
      vertical-align: inherit;
      font-size: 12px !important;
      font-family: 'Montserrat', sans-serif;
      text-align: center !important;
      padding: 0px !important;
      height: 39px !important;
      .checked-groupe {
        margin-top: 0px !important;
        padding-top: 0px !important;
        float: right !important;
      }
    }
    tr {
      .text-start,
      .custom-cell {
        border: 1px solid #e5e5e5 !important;
        vertical-align: inherit;
        // white-space: nowrap;
        font-size: 11px;
        // padding: 7px 2px !important;
        text-align: center !important;
      }
    }
  }
  thead {
    tr {
      th {
        .checked-global {
          padding-left: 8px;
        }
        span {
          font-weight: 600;
          color: black;
        }
        td {
          .v-input--selection-controls {
            margin-top: 0px !important;
            padding-top: 0px !important;
            float: right !important;
          }
        }
      }
    }
  }
}
</style>
